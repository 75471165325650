<template>
  <div class="list-info">
    <top-bar :title="'微型消防站'" :left="true"></top-bar>
    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="">
    </div>
    <!-- <van-pull-refresh v-model="pullLoading" @refresh="onPullDownRefresh"></van-pull-refresh> -->
    <div class="cont">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
        <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
        <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
          <div class="title">
            <div class="title-left">
              {{item.position}}
            </div>
            <div class="title-right">
              <a :href="'tel:'+item.mobile" @click.stop=""><img src="@/assets/img/phone.png" alt="" class="tel"/></a>
            </div>
          </div>
          <div class="content">
            <img :src="require('@/assets/img/flower.png')" class="content-img">
            <div class="content-text">
              <div class="top-text">队长: {{item.captainName}}</div>
              <div class="bottom-text">消防车: {{item.fireEngines}}</div>
            </div>
            <van-icon name="arrow" color="#666" class="content-icon"/>
          </div>
        </div>
      </van-list>
      <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
    </div>
    <!-- </van-pull-refresh> -->
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import { mapMutations } from 'vuex'
export default {
  components :{
    topBar
  },
  data() {
    return {
      page: 0,
      limit: 10,
      totalCount: 0,
      finished: false,
      pullLoading: false,
      dataList: []
    };
  },
  methods: {
    ...mapMutations,
    getInfo (id) {
      this.$router.push({path: '/mini-info', query: {id: id}})
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/minifirestation/list'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.orgId,
          page: this.page,
          limit: this.limit
        })
      }).then(({data})=> {
        this.pullLoading =false
        if (data.code == 0) {
          this.totalCount = data.page.totalCount
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          // let dataList = data.page.list
          // dataList.map(item => {
          //   if (item.inspectionTime) {
          //     item.inspectionTime = item.inspectionTime.split(' ')[0]
          //   }
          // })
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    },
    formatDate (type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    /**
     * 页面相关事件处理函数--用户下拉动作
     */
    onPullDownRefresh(e) {
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    goAdd () {
      this.$router.push('/mini-add')
    }
  },
  created () {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.userId = this.$globalData.userInfo.userId
    this.orgId = this.$orgId
    this.getDataList()
  }
}
</script>
